import React, { Component } from 'react';
import logo from 'ps-assets/img/starlogo.png';
import PropTypes from 'prop-types';
import banner from 'ps-assets/img/bannerimg.png';
import arrow from 'ps-assets/img/arrow.png';
import harrow from 'ps-assets/img/highlight_arrow.png';
import garrow from 'ps-assets/img/greenarrow.png';
import covered from 'ps-assets/img/covered.png';
import covered_h from 'ps-assets/img/covered_h.png';
import eligible from 'ps-assets/img/eligible.png';
import eligible_h from 'ps-assets/img/eligible_h.png';
import Hidden from '@material-ui/core/Hidden';
import insured from 'ps-assets/img/insured.png';
import CallComponent from "components/CallComponent";
import insured_h from 'ps-assets/img/insured_h.png';
import rupee from 'ps-assets/img/rupee.png';
import root from 'window-or-global';
import { landingPageLMSCall } from 'util/lmsRequest';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import withStyles from '@material-ui/core/styles/withStyles';
import familyhealthoptimaStyle from 'ps-assets/jss/familyhealthoptimaStyle.jsx';
import {  browserWindow } from "util/localStorageHelper";

const validationConditions = {
  name: `^[a-zA-Z .]*$`,
  phone: `^[6-9][0-9]{9}$`,
  email: `^[a-zA-Z0-9.!#$%&'*+/=?^_\`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$`,
  pincode: `[1-9][0-9]{5}$`,
};
export class FamilyHealthOptima extends Component {
  state = {
    choice: `covered`,
    check: false,
    user: {
      name: {
        mandatry: true,
        value: ``,
        error: true,
        errormsg: `Please Enter Name`,
        error1: `Please Enter Name`,
        error2: `Name should be minimum 3 and maximum 50 character`,
      },
      email: {
        mandatry: true,
        value: ``,
        error: true,
        errormsg: `Please enter correct Email ID and try again.`,
        error1: `Please enter correct Email ID and try again.`,
      },
      phone: {
        mandatry: true,
        value: ``,
        error: true,
        errormsg: `Please enter valid Phone Number`,
        error1: `Please enter valid Phone Number`,
        error2: `Phone Number should be minimum 10 Digits`,
      },

      pincode: {
        mandatry: true,
        value: ``,
        error: true,
        errormsg: `Please enter valid PinCode`,
        error1: `Please enter valid PinCode`,
        error2: `PinCode should be minimum 6 Digits`,
      },
    },
  };
  changeImg = (val) => {
    this.setState({
      choice: val,
    });
  };
  basestate = {
    user: this.state.user,
  };
  handleChange(e) {
    let change = {};
    change = JSON.parse(JSON.stringify(this.state.user));
    if (e.target.name == `name`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else if (e.target.value.length < 3) {
          change[e.target.name].errormsg = change[e.target.name].error2;
          change[e.target.name].error = true;
        } else {
          change[e.target.name].error = false;
        }
        let value = e.target.value.replace(/[^A-Za-z ]/gi, ``);
        change[e.target.name].value = value;
      }
    } else if (e.target.name == `email`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else if (
          e.target.value.search(validationConditions[e.target.name]) == -1
        ) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else {
          change[e.target.name].error = false;
        }
        change[e.target.name].value = e.target.value;
      }
    } else if (e.target.name == `phone`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else if (
          e.target.value.search(validationConditions[e.target.name]) == -1
        ) {
          change[e.target.name].errormsg = change[e.target.name].error2;
          change[e.target.name].error = true;
        } else {
          change[e.target.name].error = false;
        }
        let value = e.target.value.replace(/[^0-9]/gi, ``);
        change[e.target.name].value = value;
      }
    } else if (e.target.name == `pincode`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else if (
          e.target.value.search(validationConditions[e.target.name]) == -1
        ) {
          if(e.target.value[0]=='0'){
            change[e.target.name].errormsg = change[e.target.name].error1;
            change[e.target.name].error = true;
          }else{
            change[e.target.name].errormsg = change[e.target.name].error2;
            change[e.target.name].error = true;
          }
          
        } else {
          change[e.target.name].error = false;
        }
        let value = e.target.value.replace(/[^0-9]/gi, ``);
        change[e.target.name].value = value;
      }
    }
    this.setState({
      user: change,
    });
  }
  handleCheck = () => {
    let check1 = !this.state.check;
    this.setState({
      check: check1,
    });
  };
  handleSubmit = async (event) => {
    let error = false;
    Object.keys(this.state.user).map((key) => {
      if (this.state.user[key].error && !error) {
        alert(this.state.user[key].errormsg);
        error = true;
        return;
      }
    });
    if (this.state.check == false) {
      alert(`Read Terms and Conditions`);
      error = true;
      return;
    }
    event.preventDefault();
    if (error == false) {
      let queryParams = decodeURIComponent(browserWindow.location.search);
      queryParams = queryParams.split(`?`)[1];
      let response = await landingPageLMSCall(
        this.state.user.name.value,
        this.state.user.phone.value,
        this.state.user.email.value,
        this.state.user.pincode.value
      );
      response = await response.json();
      sessionStorage.setItem(`prevLandingPage`, `free-health-quote`);
      root.open(
        `${browserWindow.location.pathname}/thank-you?userId=${response.zohoEntityId}${
          queryParams ? `&${queryParams}` : ``
        }`,
        `_self`
      );
    }
  };
  render() {
    const { classes } = this.props;
    let feature = [
      `Avail Auto Re-charge benefit when the health cover becomes insufficient.`,
      `Get Recharge benefit upto 30% of sum insured coverage at no additional cost and can be utilized for any claim payable under the policy.`,
      `Single policy, wider sum insured coverage for whole family at a affordable premium.`,
    ];

    let dictopnaryContent = {
      covered: {
        '1': {
          title: `Hospitalized medical expenses`,
          list: [
            `Room stay and connected medical expenses.`,
            `Intensive care unit and related medical expenses.`,
          ],
        },
        '2': {
          title: `Pre & Post-hospitalization expenses`,
          list: [
            `Initial medical expense - 60 days prior.`,
            `Recovery and rehabilitation expense after discharge - 90 days after.`,
          ],
        },
        '3': {
          title: `405 Day care procedures covered without any capping on treatment cost(Not applicable for  Cataract).`,
          list: [],
        },
      },
      eligible: {
        '1': {
          title: `Who can avail?`,
          list: [
            `Persons aged *18 to 65 can take the cover (*completed year).`,
            `2 A (or 1A+1C) minimum requirement for cover.`,
          ],
        },
        '2': {
          title: `Who can enter?`,
          list: [
            ` Adults: 18 to 65`,
            `Dependent children: 16 days old to 25 years`,
          ],
        },
        '3': {
          title: `Continuity`,
          list: [` Life time renewal.`],
        },
      },
      options: {
        '1': {
          rupee: true,
          list: [
            `3 lacs`,
            `4 lacs`,
            `5 lacs`,
            `10 lacs`,
            `15 lacs`,
            `20 lacs`,
            `25 lacs`,
          ],
        },
      },
    };
    const heighlights = [
      ' Get extra sum insured (auto recharge) at no extra cost.',
      '3 times Automatic restoration of Sum insured by 100% each time.',
      'New-born baby cover from 16th day.',
      'Health Checkup benefit for every claim free year.',
      'Cover for Assisted Reproductive Treatment.',
      'Donor expenses for organ transplantation.',
    ];
   
    return (
      <>
        <div style={{ background: `white` }}>
          <div className={classes.logodiv}>
            <div>
              <div className={classes.logodiv1}>
                <img
                  src={logo}
                  style={{ maxWidth: `none`, marginTop: `1%` }}
                  alt='Star Health'
                />{' '}
                <div className={classes.calldiv}>
                  <LocalPhoneIcon style={{ marginBottom: `-2%` }} />
                  <CallComponent color={'white'} contactNumber="044 6900 6900"/> <CallComponent color={'white'}  style={{color:'white'}}  contactNumber="044 4674 5800"/> 
                </div>
              </div>
            </div>
          </div>
          <div className={classes.headerstyle}>
            <div className={classes.content1Style}>
              <div className={classes.header}>STAR FAMILY HEALTH OPTIMA</div>
              <Hidden mdUp>
              <div className={classes.formdivstyle}>
                <form
                  onSubmit={this.handleSubmit}
                  className={classes.formContainer}
                >
                  <p className={classes.formdiv2style}>Lets get started</p>
                  <div>
                    <span></span>
                    <input
                      type='text'
                      name='name'
                      placeholder='Name*'
                      value={this.state.user.name.value}
                      onChange={this.handleChange.bind(this)}
                      className={classes.inputstyle}
                    />
                  </div>
                  <div>
                    <span></span>
                    <input
                      type='text'
                      name='email'
                      value={this.state.user.email.value}
                      onChange={this.handleChange.bind(this)}
                      placeholder='Email ID*'
                      className={classes.inputstyle}
                    />
                  </div>

                  <div>
                    <span></span>
                    <input
                      type='text'
                      name='phone'
                      placeholder='Mobile*'
                      value={this.state.user.phone.value}
                      onChange={this.handleChange.bind(this)}
                      maxLength='10'
                      className={classes.inputstyle}
                    />
                  </div>
                  <div>
                    <span></span>
                    <input
                      type='text'
                      name='pincode'
                      placeholder='PinCode*'
                      value={this.state.user.pincode.value}
                      onChange={this.handleChange.bind(this)}
                      maxLength='6'
                      className={classes.inputstyle}
                    />
                  </div>
                  <div style={{ fontSize: `12px` }}>
                    <div className={classes.fieldmanndatorystyle}>
                      *all fields are mandatory{` `}
                    </div>
                    <div className={classes.authorizestyle}>
                      <input
                        type='checkbox'
                        name='checkbox'
                        title='Please accept terms and conditions'
                        id='checkbox'
                        onChange={this.handleCheck}
                      />
                      {` `}
                      {` `}I have read and agree to the . I{` `}
                      <a
                        href='/static-privacy-policy'
                        target='_blank'
                        ref={`noopener noreferrer`}
                        style={{ color: `#333333` }}
                      >
                        <u>privacy policy</u>
                      </a>
                      <div>
                        authorize Star Health And Allied Insurance to contact me via
                        email, phone or SMS.
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div>
                        <button
                          type='submit'
                          id='contact-form-submit'
                          style={{ cursor: 'pointer' }}
                          className={classes.submitdivstyle}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
             </Hidden>
              <div className={classes.keyfeature}>Key Features</div>
              <ul className={classes.unorderListstyle1}>
                {feature.map((item, index) => (
                  <li key={index} className={classes.list1style}>
                    <div className={classes.arrowdivStyle1}>
                      <img src={arrow} className={classes.arrowIconStyle1} />
                    </div>
                    <div style={{ display: 'flex' }}> {item}</div>
                  </li>
                ))}
              </ul>
              {/* <div style={{ marginTop: `36px` }}>
                <a
                  href='/health-insurance-plans-for-family'
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.abutton}
                >
                  Buy Now
                </a>
              </div> */}
            </div>
            <div className={classes.bannerPsosition}>
              <img src={banner} style={{ width: 'auto', height: 'auto' }}></img>
            </div>
            <div className={classes.highlightstyle}>
              HIGHLIGHTS
              <img src={harrow} style={{ marginLeft: `7px` }} />
            </div>
          </div>
        </div>
        <div className={classes.list2idv}>
          <ul className={classes.unorderposition}>
            {heighlights.map((item, index) => (
              <li
                key={index}
                className={classes.listitemstyle}
                style={{ listStyle: 'none' }}
              >
                <div
                  style={{
                    float: 'left',
                    paddingRight: '10px',
                    marginTop: '-2px',
                  }}
                >
                  <img src={garrow} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    color: 'white',
                    fontSize: '13px',
                    fontFamily: 'system-ui',
                    fontWeight: '400',
                  }}
                >
                  {item}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div style={{ background: 'white' }}>
          <div style={{ background: `white` }}>
            <div className={classes.buttonstyle}>
              <div className={classes.tabContainer}>
                <img
                  src={this.state.choice === `covered` ? covered_h : covered}
                  className={classes.tabOption}
                  onClick={() => {
                    this.changeImg(`covered`);
                  }}
                />
                {` `}
              </div>
              <div className={classes.tabContainer}>
                <img
                  src={this.state.choice === `eligible` ? eligible_h : eligible}
                  onClick={() => {
                    this.changeImg(`eligible`);
                  }}
                  className={classes.tabOption}
                />
                {` `}
              </div>
              <div className={classes.tabContainer}>
                <img
                  src={this.state.choice === `options` ? insured_h : insured}
                  onClick={() => {
                    this.changeImg(`options`);
                  }}
                  className={classes.tabOption}
                />
                {` `}
              </div>
            </div>
            <div className={classes.divContainer}>
              {Object.keys(dictopnaryContent[this.state.choice]).map((key) => (
                <div key={key}>
                  <div className={classes.divbackground}>
                    <div className={classes.divtitlestyle}>
                      {dictopnaryContent[this.state.choice][key].title &&
                        dictopnaryContent[this.state.choice][key].title}
                    </div>
                    <ul>
                      {Object.keys(
                        dictopnaryContent[this.state.choice][key].list
                      ).map((key1) => (
                        <li key={key1} className={classes.list3style}>
                          <img
                            src={garrow}
                            style={{ marginLeft: `-13px`, marginRight: `10px` }}
                          />
                          {dictopnaryContent[this.state.choice][key].rupee && (
                            <img
                              src={rupee}
                              style={{
                                marginLeft: `-4px`,
                                marginRight: `7px`,
                              }}
                            />
                          )}

                          {dictopnaryContent[this.state.choice][key].list[key1]}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div
          className={classes.formdivstyle}
          > */}
          <Hidden smDown>
          <div className={classes.formdivstyle}>
            <form
              onSubmit={this.handleSubmit}
              className={classes.formContainer}
            >
              <p className={classes.formdiv2style}>Lets get started</p>
              <div>
                <span></span>
                <input
                  type='text'
                  name='name'
                  placeholder='Name*'
                  value={this.state.user.name.value}
                  onChange={this.handleChange.bind(this)}
                  className={classes.inputstyle}
                />
              </div>
              <div>
                <span></span>
                <input
                  type='text'
                  name='email'
                  value={this.state.user.email.value}
                  onChange={this.handleChange.bind(this)}
                  placeholder='Email ID*'
                  className={classes.inputstyle}
                />
              </div>

              <div>
                <span></span>
                <input
                  type='text'
                  name='phone'
                  placeholder='Mobile*'
                  value={this.state.user.phone.value}
                  onChange={this.handleChange.bind(this)}
                  maxLength='10'
                  className={classes.inputstyle}
                />
              </div>
              <div>
                <span></span>
                <input
                  type='text'
                  name='pincode'
                  placeholder='PinCode*'
                  value={this.state.user.pincode.value}
                  onChange={this.handleChange.bind(this)}
                  maxLength='6'
                  className={classes.inputstyle}
                />
              </div>
              <div style={{ fontSize: `12px` }}>
                <div className={classes.fieldmanndatorystyle}>
                  *all fields are mandatory{` `}
                </div>
                <div className={classes.authorizestyle}>
                  <input
                    type='checkbox'
                    name='checkbox'
                    title='Please accept terms and conditions'
                    id='checkbox'
                    onChange={this.handleCheck}
                  />
                  {` `}
                  {` `}I have read and agree to the . I{` `}
                  <a
                    href='/static-privacy-policy'
                    target='_blank'
                    ref={`noopener noreferrer`}
                    style={{ color: `#333333` }}
                  >
                    <u>privacy policy</u>
                  </a>
                  <div>
                    authorize Star Health And Allied Insurance to contact me via
                    email, phone or SMS.
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <button
                      type='submit'
                      id='contact-form-submit'
                      style={{ cursor: 'pointer' }}
                      className={classes.submitdivstyle}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          </Hidden>
        </div>
        <div className={classes.footerstyle} style={{ background: 'white' }}>
          <div></div> <br />
          <div className={classes.footertextstyle}>
            <div style={{ fontWeight: `bold` }}>
              IRDAI REGISTRATION NUMBER. 129
            </div>
            <div>
              For complete details on coverage, terms, conditions and
              exclusions,
            </div>
            <div>
              please read the Policy document carefully. Insurance is the
              subject matter of solicitation.
            </div>
            {` `}
            <br />
            <div style={{ fontWeight: `bold` }}>
              Star Health and Allied Insurance Company Limited.
            </div>
            <div>
              Corporate Office: No.1, New Tank Street, Valluvar Kottam High
              Road, Nungambakkam,
            </div>
            <div>Chennai - 600034. Sales & Services - 044 4674 5800</div>
            <br />
            <div>
              For further details visit{` `}
              <u>
                <a
                  href='https://www.starhealth.in/'
                  style={{
                    color: `#999999`,
                  }}
                >
                  www.starhealth.in
                </a>
              </u>
            </div>
            <div style={{ fontWeight: `bold` }}>SHAI/14-15/Online/FHO/001</div>
          </div>
        </div>
      </>
    );
  }
}
FamilyHealthOptima.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(familyhealthoptimaStyle)(FamilyHealthOptima);
