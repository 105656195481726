const familyhealthoptimaStyle = () => ({
  abutton: {
    fontSize: '15px',
    color: '#fff',
    border: '2px solid #224490',
    borderRadius: '5px 5px 5px 5px',
    padding: '9px 10px',
    textDecoration: 'none',
    background: '#224490',
    '&:hover': {
      color: '#224490',
      background: '#fff',
    },
  },
  logodiv: {
    width: '100%',
    height: '5%',
    float: 'left',
    display: 'inline',
    background: '#224490',
  },
  logodiv1: {
    paddingRight: '15px',
    marginRight: 'auto',
    paddingLeft: '13%',
    width: '100%',
    marginLeft: 'auto',
    marginBottom: '2.4%',
    '@media (max-width: 1025px)': {
      marginBottom: '4%',
    },
    
  },
  calldiv: {
    fontSize: '14px',
    color: '#FFF',
    fontFamily: 'system-ui',
    width: '100%',
    paddingLeft: '62.5%',
    fontWeight: '400',
    marginTop: '-4.5%',
    cursor:'pointer',
    '@media (max-width: 1025px)': {
      marginTop: '-5.5%',
    },
    '@media (max-width: 770px)': {
      marginTop: '-7.5%',
    },  
    '@media (max-width: 690px)': {
      paddingLeft: '48.5%',
    },
    '@media (max-width: 580px)': {
      paddingLeft: '0%',
      marginTop: '4.5%',
    },
  },
  headerstyle: {
    width: '100%',
    float: 'left',
    paddingLeft: '13%',
    display: 'inline',
    background: 'white',
    '@media (max-width: 1041px)': {
      paddingLeft: '2%',
    },
  },
  header: {
    margin: '7% 0 1% 0',
    fontSize: '38px',
    color: '#224490',
    fontFamily: 'system-ui',
    fontWeight: '600',
    letterSpacing: '1px',
    '@media (max-width: 1025px)': {
      margin: '5% 0 1% 0',
      paddingLeft: '3%',
      paddingBottom:'2%'
    },
    '@media (max-width: 700px)': {
      fontSize: '26px',
      marginLeft: '3%',
    },
  },
  unorderListstyle1: {
    padding: '0px',
    '@media (max-width: 1025px)': {
      paddingLeft: '3%',
    },
    '@media (max-width: 700px)': {
      marginLeft: '3%',
    },
  },
  keyfeature: {
    marginTop: '3%',
    marginBottom: '1%',
    fontSize: '19px',
    color: '#224490',
    '@media (max-width: 1025px)': {
      paddingLeft: '3%',
    },
    '@media (max-width: 700px)': {
      marginLeft: '3%',
    },
  },
  list1style: {
    listStyleType: 'none',
    color: '#333333',
    fontSize: '14px',
    fontFamily: 'system-ui',
    // width: '77.5%',
    padding: '2px 0 5px 0px',
    width: '576px',
    lineHeight: '20px',
    marginBottom: '1%',
    fontWeight: '400',
    '@media (max-width: 1041px)': {
      width: '98%',
    },
  },
  listitemstyle: {
    width: '30%',
    listStyle: 'none',
    float: 'left',
    '@media (max-width: 1041px)': {
      width: '45% !important',
      listStyle: 'none',
    },
    '@media (max-width: 665px)': {
      width: '90% !important',
      listStyle: 'none',
    },
    '@media (max-width: 450px)': {
      width: '98% !important',
      listStyle: 'none',
    },
  },
  arrowIconStyle1: {
    padding: ' 8px',
  },
  arrowdivStyle1: {
    float: 'left',
  },
  bannerPsosition: {
    marginLeft: `42%`,
    marginTop: `-21%`,
    '@media (max-width: 1041px)': {
      marginLeft: `-92px`,
      marginTop: `0%`,
      width: '100%',
      textAlign: 'center',
    },
    '@media (max-width: 500px)': {
      marginLeft: `-129px`,
      marginTop: `0%`,
      width: '100%',
      textAlign: 'center',
    },
    '@media (max-width: 430px)': {
      marginLeft: `-153px`,
      marginTop: `0%`,
      width: '100%',
      textAlign: 'center',
    },
    '@media (max-width: 382px)': {
      marginLeft: `-153px`,
      marginTop: `0%`,
      width: '100%',
      textAlign: 'center',
    },
    '@media (max-width: 350px)': {
      marginLeft: `-183px`,
      marginTop: `0%`,
      width: '100%',
      textAlign: 'center',
    },
  },
  highlightstyle: {
    fontSize: '20px',
    color: '#009bdb',
    marginTop: '-11px',
    fontWeight: '500',
    marginBottom: '11px',
    '@media (max-width: 1041px)': {
      marginTop: '19px',
      marginBottom: '11px',
    },
  },
  content1Style: {
    width: '67%',
    '@media (max-width: 1041px)': {
      width: '100% !important',
    },
  },
  list2idv: {
    paddingTop: '1.2%',
    background: '#009bdb',
    marginBottom: '2.3%',
    display: 'grid',
    paddingLeft: '9.9%',
    paddingRight: '4.9%',
    width: '100%',
    '@media (max-width: 450px)': {
      paddingLeft: '0%',
      paddingRight: '2.9%',
    },
  },
  unorderposition: {
    '@media (max-width: 450px)': {
      paddingLeft: '5%',
      list1style: 'none',
    },
  },
  listsstyle: {
    listStyleType: 'none',
    color: 'white',
    fontSize: '14px',
    marginTop: '-0.5%',
    fontFamily: 'system-ui',
    marginLeft: '-2.3%',
    width: '48%',
  },
  buttonstyle: {
    width: '67%',
    height: '4%',
    marginTop: '-2.7%',
    borderRadius: '5px',
    display: 'inline',
    marginLeft: '12.65%',
    float: 'left',
    marginBottom: '14px',
    '@media (max-width: 700px)': {
      marginLeft: '2.5%',
      marginBottom: '6px',
    },
    '@media (max-width: 400px)': {
      width: '100%',
      marginLeft: '2.5%',
      marginBottom: '1px',
    },
  },
  divContainer: {
    width: '48%',
    height: '40%',
    float: 'left',
    border: '1px solid #c7c7c7',
    borderRadius: '5px',
    marginLeft: '13%',
    marginTop: '-2%',
    display: 'inline-block',
    background: 'white',
    padding: '4px 3px',
    marginBottom: '1%',
    // '@media (max-width: 1041px)': {
    //   width: '86%',
    // },
    '@media (max-width: 1041px)': {
      width: '94.6%',
      marginLeft: '3%',
    },
  },
  divbackground: {
    background: 'rgb(240, 251, 255)',
    color: '#333333',
    fontSize: '14px',
    fontFamily: 'system-ui',
    padding: '10px 0px 5px 23px',
    marginLeft: '2%',
    width: '95%',
    marginTop: '1.8%',
    marginBottom: '1%',
    '@media (max-width: 500px)': {
      padding: '5px 0px 5px 9px',
    },
  },
  divtitlestyle: {
    marginLeft: '-1.2%',
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: '500',
    fontFamily: 'sans-serif',
    color: '#000000',
    marginBottom: '1%',
  },
  list3style: {
    listStyleType: 'none',
    color: 'black',
    fontSize: '14px',
    // lineHeight: '15px',
    fontFamily: 'system-ui',
    paddingLeft: '0px',
    width: '76%',
    lineHeight: '22px',
    marginLeft: '-5%',
    marginBottom: '2px',
    '@media (max-width: 500px)': {
      width: '100%',
    },
  },
  formdivstyle: {
    width: '24%',
    float: 'left',
    marginLeft: '1.5%',
    marginTop: '-1.5%',
    background: '#cee0ea',
    borderRadius: '5px',
    marginBottom: '2%',
    '@media (max-width: 1041px)': {
      width: '94.5%',
      display: 'flex',
      marginLeft: '3%',
      marginTop: '0%',
    },
    '@media (max-width: 500px)': {
      width: '94.5%',
      display: 'flex',
      marginLeft: '3%',
      marginTop: '5%',
      paddingBottom:'2.5%'
    },
  },
  formdiv2style: {
    marginBottom: '1.3%',
    fontSize: '30px',
    width: '100%',
    height: '69px',
    // marginLeft: '-2.3%',
    marginTop: '-10.3%',
    paddingTop: '7%',
    borderRadius: '5px',
    fontWeight: '400',
    paddingLeft: '6%',
    background: '#bfd0d9',
    color: '#333333',
    '@media (max-width: 1041px)': {
      height: '70px',
      paddingTop: '3.3%',
      marginTop: '0%',
    },
    '@media (max-width: 400px)': {
      height: '70px',
      paddingTop: '7.3%',
      marginTop: '0%',
    },
  },
  formContainer: {
    width: '100%',
  },
  inputstyle: {
    width: '87%',
    height: '34px',
    border: '1px solid #666666',
    borderRadius: '5px 5px 5px 5px',
    color: '#666666',
    fontSize: '13px',
    marginTop: ' 4.3%',
    marginLeft: '6.5%',
    lineHeight: '28px',
    margin: '0 auto',
    backgroundColor: 'transparent',
    padding: '0 6px',
  },
  submitdivstyle: {
    width: '18%',
    height: '24px',
    marginLeft: '6.5%',
    marginBottom : '1.3%'
  },
  footerstyle: {
    width: '100%',
    textAlign: 'center',
    fontSize: '14px',
    // marginTop: '50%',
    marginTop: '-2.5%',
    color: '#999999',
    lineHeight: '18px',
    '@media (max-width: 1041px)': {
      marginTop: '-2.5%',
    },
  },
  tabContainer: {
    float: `left`,
    marginTop: `20px`,
    cursor: 'pointer',
    marginLeft: `5px`,
    '@media (max-width: 700px)': {
      width: '30%',
    },
  },
  tabOption: {
    '@media (max-width: 700px)': {
      width: '100%',
    },
  },
  footertextstyle: {
    background: '#f5f5f5',
    marginTop: '33.3%',
    paddingBottom: '2%',
    paddingTop: '3%',
    fontSize: '14px',
    '@media (max-width: 1041px)': {
      width: '100%',
      display: 'inline-block',
      marginTop: '5.3%',
    },
    '@media (max-width: 400px)': {
      paddingLeft: '1.5%',
      paddingRight: '1.5%',
    },
  },
  fieldmanndatorystyle: {
    marginTop: `3.4%`,
    marginBottom: `1.6%`,
    marginLeft: '6.5%',
    color: '#333333',
    fontWeight: '300',
  },
  authorizestyle: {
    paddingLeft: '6.5%',
    lineHeight: '15px',
    width: '92%',
    marginTop: '4%',
    
    '@media (max-width: 576px) ': {
      marginBottom: '3%',
    },
    '@media (min-width: 576px)': {
      marginBottom: '6%',
    },
    '@media (min-width: 768px)': {
      marginBottom: '3%',
    },
    '@media (min-width: 992px)': {
      marginBottom: '6%',
    },
    '@media (min-width: 1200px)': {
      marginBottom: '6%',
    },
    
    '@media (min-width: 1400px)': {
      marginBottom: '6%',
    },

    '@media only screen and (min-width: 1024px)  and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)' : {
      marginBottom: '3%',
    }


  },
  highlightlist1style: {
    marginLeft: '3px',
    marginTop: '-3.6%',
    lineHeight: '53px',
  },
  highlightlist2style: {
    marginLeft: '3px',
    marginTop: '-3.5%',
    lineHeight: '10px',
  },
  highlightlist3style: {
    marginLeft: '3px',
    marginTop: '-3.9%',
    lineHeight: '16px',
  },
});

export default familyhealthoptimaStyle;
